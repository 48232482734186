const submitForm = async ({
    email,
    firstName,
    lastName,
    middleName,
    mobileNo,
    planId,
    autoRenew,
    pref,
    gender,
    dob,
    tin,
    province,
    city,
    barangay,
    zipCode
}) => {
    try {
        const url = window.location.href;
        const isLocalhost = url.includes('localhost') || url.includes('pa365.dev.bpimsapps.com');
        const uat = url.includes('pa365.uat.bpimsapps.com'); 
        const prod = url.includes('pa365.bpims.com');
        const [, stage, , flag] = url.split('.');

        const endpoint = isLocalhost
            ? `https://partners-api.dev.bpimsapps.com/${autoRenew ? 'register' : 'register/onetime'}`
            : uat
                ? `https://partners-api.uat.bpimsapps.com/${autoRenew ? 'register' : 'register/onetime'}`
                : prod
                    ? `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${flag ? '' : '/partners'}/${autoRenew ? (flag ? 'register' : 'register/recurring') : 'register/onetime'}`
                    : `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${flag ? '' : '/partners'}/${autoRenew ? (flag ? 'register' : 'register/recurring') : 'register/onetime'}`;

        const response = await fetch(endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    policy: {
                        policyType: 'PA365',
                        autoRenewTag: autoRenew,
                        planId,
                        customer: {
                            email: email.toLowerCase(),
                            firstName: firstName
                                ? firstName.toUpperCase
                                    ? firstName.toUpperCase()
                                    : firstName
                                : firstName,
                            lastName: lastName ? (lastName.toUpperCase ? lastName.toUpperCase() : lastName) : lastName,
                            middleName: middleName
                                ? middleName.toUpperCase
                                    ? middleName.toUpperCase()
                                    : middleName
                                : middleName,
                            mobileNo,
                            gender,
                            pref,
                            dob,
                            tin,
                            province,
                            city,
                            barangay,
                            zipCode
                        }
                    }
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default submitForm;
