import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`;

const HiddenInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ isActive }) => (isActive ? '#fff' : '#fff')};
    transition: background-color 0.4s;
    border-radius: 34px;
    border: 1px solid ${({ isActive }) => (isActive ? '#6743be' : '#a8c3fb')};

    &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: ${({ isActive }) => (isActive ? '30px' : '4px')};
        bottom: 4px;
        background-color: ${({ isActive }) => (isActive ? '#6743be' : '#a8c3fb;')};
        border-radius: 50%;
        transition: left 0.4s;
    }
`;

const ToggleSwitch = ({ value, onChange }) => {
    return (
        <SwitchContainer>
            <HiddenInput type="checkbox" checked={value} onChange={onChange} />
            <Slider isActive={value} />
        </SwitchContainer>
    );
};

export default ToggleSwitch;
