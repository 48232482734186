import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const StyledContainer = styled(Container)`
    &&& {
        background: black;
    }

    @media (max-width: 600px) {
        padding: 20px 55px !important;
    }
`;

const StyledStack = styled(Stack)`
    &&& {
        color: rgba(249, 249, 249, 1);
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        padding: 5% 0;
    }
`;

const StyledParagraph = styled.p`
    &&& {
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        margin: 0;
    }
`;

const StyledButton = styled.button`
    &&& {
        border-radius: 32px;
        background: rgb(242, 216, 65);
        z-index: 1;
        transition: all 0.2s ease-in 0s;
        padding: 5px 20px;
        border-width: 0;
        max-width: fit-content;
        margin: 20px auto;
    }
`;

const StyledH3 = styled.h3`
    font-size: 36px;
`;

const StyledLink = styled.a`
    color: white;
`;

export default function Claim() {
    return (
        <StyledContainer fluid id="contact-us">
            <Row>
                <Col lg={12} className="justify-content-center text-center">
                    <StyledStack className="text-white">
                        <StyledH3>File a Claim</StyledH3>
                        <StyledParagraph>
                            For inquiries, please call the BPI MS Helpline at (+632) 8840-9000.
                        </StyledParagraph>
                        <StyledParagraph>
                            To file a claim, please email{' '}
                            <StyledLink href="mailto:claims@bpims.com">claims@bpims.com</StyledLink>.
                        </StyledParagraph>
                        <StyledButton onClick={() => window.open('tel:+63288409000')}>CALL THE HOTLINE</StyledButton>
                    </StyledStack>
                </Col>
            </Row>
        </StyledContainer>
    );
}
