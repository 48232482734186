import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

const StyledNavbar = styled(Navbar)`
    &&& {
        padding-top: 2px;
        background-color: #ffeac4;
    }

    @media (max-width: 768px) {
        padding: 2px 10px 0 10px;
    }
`;

const StyledContainer = styled(Container)`
    &&& {
        padding: 5px 15px;
    }

    @media (max-width: 768px) {
        justify-content: start !important;
        gap: 10px;
    }
`;

const StyledNav = styled(Nav)`
    &&& {
        font-family: Poppins, sans-serif;
        text-align: center;
    }
`;

const StyledNavLink = styled(Nav.Link)`
    &&& {
        color: rgba(0, 0, 0, 0.8);
        margin-right: 8px;
        letter-spacing: 0.5px;
        z-index: 2;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
    }

    @media (max-width: 768px) {
        text-align: left;
    }
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
    margin-top: 20px;

    @media (max-width: 600px) {
        margin: 0 5% !important;
    }
`;

const StyledNavbarCollapse = styled(Navbar.Collapse)`
    flex-direction: column;
    align-items: self-start;
    margin-left: 150px;

    @media (max-width: 600px) {
        margin: 0 !important;

        a {
            text-align: center;
        }
    }
`;

const BrandLogo = styled(Image)`
    max-height: 54px;
`;

export default function AppNavbar() {
    return (
        <StyledNavbar expand="lg">
            <StyledContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <StyledNavbarBrand className="pb-0">
                    <BrandLogo src={require('../assets/images/bpims-logo.png')} style={{ maxHeight: '54px' }} fluid />
                </StyledNavbarBrand>
                <StyledNavbarCollapse id="basic-navbar-nav">
                    <StyledNav className="justify-content-center">
                        <StyledNavLink href="https://bpims.com/" target="bpims">
                            BPI MS WEBSITE
                        </StyledNavLink>
                        <StyledNavLink
                            href="https://bpims.com/wp-content/uploads/2023/09/Insuring-TCs-PA-Individual-PA365.pdf"
                            target="bpims"
                        >
                            POLICY TERMS & CONDITIONS
                        </StyledNavLink>
                        <StyledNavLink href="#contact-us" id="contact-us-button">
                            CONTACT US
                        </StyledNavLink>
                    </StyledNav>
                </StyledNavbarCollapse>
            </StyledContainer>
        </StyledNavbar>
    );
}
