const getAddress = async () => {
    try {
        const url = window.location.href;
        const isLocalhost = url.includes('localhost') || url.includes('pa365.dev.bpimsapps.com');
        const uat = url.includes('pa365.uat.bpimsapps.com'); 
        const prod = url.includes('pa365.bpims.com');
        const [, stage, , flag] = url.split('.');

        const endpoint = isLocalhost
            ? 'https://partners-api.dev.bpimsapps.com/data-get/geo'
            : uat
            ? 'https://partners-api.dev.bpimsapps.com/data-get/geo'
            : prod
            ? `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${
                  flag ? '' : '/partners'
              }/data-get/geo`
            : `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${
                  flag ? '' : '/partners'
              }/data-get/geo`;

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tables: ['province', 'city', 'barangay']
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('getAddress api error', error);
        throw error;
    }
};

export default getAddress;